import { z } from 'zod';
import { SiteMap } from '~/classes/sitemap';
import { SiteMapSchema} from '~/schemas/sitemap';

export const useSiteMapStore = defineStore( 'siteMapStore', () => {


    //Props
    const siteMap = ref<SiteMap | null>(null);
    let siteMapData = ref<z.infer<typeof SiteMapSchema>>(null); 

    //Getters
    const getSiteMap = computed(
        () => {
            return siteMap.value as SiteMap | null;
        }
    );
    const getSiteMapData = computed(() => {
            return siteMapData;
    });

    //Actions

    async function fetchSiteMap() {

        await fetchJsonData('sitemap/sitemap')
            .then(
                (siteMapJson) => { 
                    const stringified = JSON.stringify(siteMapJson);

                     siteMapData = parseJSONObjectV2(SiteMapSchema, stringified) ;
                     siteMap.value = new SiteMap(siteMapJson);
                    
                }
            )
            .catch(
                (error) => {
                    // Log error to Azure Log Analytics or Alert GIBS
                    console.error(JSON.stringify(error, null, 2))
                    siteMapData = null;  
                    siteMap.value = null;
                }
            );
    }

    return {
        getSiteMap,
        getSiteMapData,
        fetchSiteMap
    }
})