import { any, z } from 'zod';

export interface SiteMapContentItemSchema {
    Name: string;
    URL: string;
    ContentPages: SiteMapContentItemSchema[];
  }

  export const SiteMapContentItemSchema: z.ZodType<SiteMapContentItemSchema> = z.lazy(() => {
    return z.object({
        Name: z.string(),
        URL: z.string(),
        ContentPages: z.array(SiteMapContentItemSchema)
    });
  });


export const SiteMapItemSchema = z.object({
    Name: z.string(),
    URL: z.string()
});

export const ApplicationSchema = z.object({
    Name: z.string(),
    URL: z.string(),
    CourseClassification: z.string()
});

export const ProgrammeSchema = z.object({
    Name: z.string(),
    URL: z.string(),
    CourseClassification: z.string(),
    Applications: z.array(ApplicationSchema)
});

export const PagesSchema = z.object({
    content: z.array(SiteMapContentItemSchema),
    faculty: z.array(SiteMapItemSchema),
    news: z.array(SiteMapItemSchema),
    courselist: z.array(SiteMapItemSchema),
    Programmes: z.array(ProgrammeSchema)
  });

export const SiteMapSchema = z.object({
    Name: z.string(),
    URL:  z.string(),
    Category: z.string(),
    Pages: PagesSchema
  }).nullable();